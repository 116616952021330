import numetryLogo from 'assets/images/company/numetry-logo-nn.png'

import SignInPassword from 'assets/audios/nn/NN-signin-password.mp3'
import SignInUsername from 'assets/audios/nn/NN-signin-username.mp3'

import SignUpUsername from 'assets/audios/nn/NN-signup-username.mp3'
import SignUpPassword from 'assets/audios/nn/NN-signup-password.mp3'
import SignUpReview from 'assets/audios/nn/NN-signup-review.mp3'
import Character from 'assets/audios/nn/NN-choose-your-character.mp3'

import { PagesLocaleTypes } from '../locales.types'

const pagesLocaleNn: PagesLocaleTypes = {
  landing: {
    imPlayingFrom: `Akkurat no spelar eg Numetry på...`,
    modality: {
      school: 'Skulen',
      freeTime: 'Fritida'
    }
  },
  home: {
    logo: numetryLogo,
    newUser: 'Ny brukar',
    signIn: 'Logg inn',
    haveYouPlayed: 'Har du spelt Numetry før?',
    logInWithFeide: 'Logg inn med Feide',
    logInWithoutFeide: 'Logg inn uten Feide',
    createNewUser: 'Lag en ny bruker',
    doesNotHaveFeide: `Har ikke skolen din Feide-pålogging i Numetry?\n\n Kontakt oss på [{{ email }}](mailto:{{ email }}?subject=Tilgang%20til%20Feide) eller [trykk her]({{ formLink }}) for å komme i gang gratis i dag.`,
    or: 'eller',
    footer: {
      aGameBy: 'Eit spel frå'
    }
  },
  unsuportedDevice: {
    title:
      'Numetry kan foreløpig kun spilles på PC/Chromebook/Mac eller via vår iPad-app.',
    button: 'Åpne Numetry i iPad-appen.',
    footer: 'Hvis du ikke har appen. <0>Last den ned fra appbutikken.</0>'
  },
  logout: {
    loggingOut: 'Logger ut...'
  },
  auth: {
    validationError: {
      requiredField: 'Obligatorisk felt'
    },
    redirectToGame: {
      redirectingToGame: 'Omdirigerer til spillet...',
      continueAs: 'Fortset som:',
      yes: 'Ja',
      noLogOut: 'Nei, logg ut'
    },
    character: {
      contextButton: {
        playNumetry: 'Spel Numetry'
      },
      title: 'Vel din karakter.',
      audio: Character,
      input: {
        placeholder: 'Gi eit namn til din karakter'
      },
      buttons: {
        save: {
          value: 'Lagre'
        },
        clearName: {
          value: 'Namn klart!'
        },
        change: {
          value: 'Endre'
        }
      }
    },
    signUp: {
      usernameInUse: 'Brukernavnet er allerede i bruk, velg et annet.',
      usernameCannotBeUsed: `Dette brukernavnet kan ikke bli brukt.`,
      usernameStep: {
        audio: SignUpUsername,
        beforeYouCanStart:
          'Hei! Før du kan begynne å spele, må du lage ein brukar.',
        usernameAlreadyTaken: 'Brukarnamn allereie tatt. Forslag:',
        username: 'Brukarnamn'
      },
      passwordStep: {
        audio: SignUpPassword,
        alsoCreatePassword: 'Du må òg lage eit passord.',
        password: 'Passord',
        passwordRepeat: 'Same passord som over:',
        inputPlaceholder: 'Passord med minst {{ minPasswordLength }} teikn',
        minimumPasswordCharacters:
          'Passordet ditt må inneholde minst {{ minPasswordLength }} teikn.',
        passwordNotEqual: 'Passorda må vere like.',
        togglePasswordVisibility: 'Vis passord'
      },
      reviewStep: {
        audio: SignUpReview,
        greatRememberThis:
          'Flott! Dette må du hugse for å logge inn neste gong. Skriv det ned!',
        username: 'Brukarnamn:',
        password: 'Passord:',
        iveWrittenItDown: 'Eg har skrive det ned'
      }
    },
    signIn: {
      usernameStep: {
        audio: SignInUsername,
        enterYourUsername: 'Skriv inn brukarnamnet ditt for å logge inn.',
        enterYourUsernameInput: 'Skriv inn brukarnamnet ditt',
        notPlayedBefore: 'Ikkje spelt Numetry før?',
        createNewUser: 'Opprett ny brukar',
        usernameError:
          'Vi fant ikkje dette brukarnamnet. Prøv igjen. Har du gløymt brukarnamnet ditt, må du lage ein ny brukar.'
      },
      passwordStep: {
        audio: SignInPassword,
        youMustEnterYourPassword: 'Du må også skrive inn passordet ditt.',
        passwordWithMinCharacter:
          'Passord med minst {{ minPasswordLength }} teikn',
        passwordError:
          'Brukernavn eller passord samsvarer ikke med en gyldig konto.'
      }
    }
  },
  municipality: {
    getStartedWithFeide: 'Kom i gang med Feide gratis'
  }
}

export default pagesLocaleNn

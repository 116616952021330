import numetryLogo from 'assets/images/company/numetry-logo-en.png'
import SignInPassword from 'assets/audios/en/EN-signin-password.mp3'
import SignInUsername from 'assets/audios/en/EN-signin-username.mp3'
import SignUpUsername from 'assets/audios/en/EN-signup-username.mp3'
import SignUpPassword from 'assets/audios/en/EN-signup-password.mp3'
import SignUpReview from 'assets/audios/en/EN-signup-review.mp3'
import Character from 'assets/audios/en/EN-choose-your-character.mp3'

import { PagesLocaleTypes } from '../locales.types'

const pageLocaleEs: PagesLocaleTypes = {
  landing: {
    imPlayingFrom: 'En estos momentos estoy jugando Numetry en mi…',
    modality: {
      school: 'Escuela',
      freeTime: 'Tiempo libre'
    }
  },
  home: {
    logo: numetryLogo,
    newUser: 'Nuevo usuario',
    signIn: 'Iniciar sesión',
    haveYouPlayed: '¿Ya has jugado Numetry?',
    logInWithFeide: 'Iniciar sesión con Feide',
    logInWithoutFeide: 'Iniciar sesión sin Feide',
    createNewUser: 'Crear un nuevo usuario',
    doesNotHaveFeide: `¿Su escuela no tiene início de sesión de Feide para Numetry?\n\n Contáctenos en [{{ email }}](mailto:{{ email }}?subject=Acceso%20a%20Feide) o haga [clic aquí]({{ formLink }}) para comenzar gratis hoy.`,
    or: 'o',
    footer: {
      aGameBy: 'Desarrollado por'
    }
  },
  unsuportedDevice: {
    title:
      'Numetry actualmente solo se puede jugar en PC/Chromebook/Mac o a través de nuestra aplicación para iPad',
    button: 'Abre Numetry en la aplicación para iPad.',
    footer:
      'Si no tienes la aplicación. \n\n <0>Consíguela en la tienda de aplicaciones.</0>'
  },
  logout: {
    loggingOut: 'Cerrando sesión...'
  },
  auth: {
    validationError: {
      requiredField: 'Campo obligatorio'
    },
    redirectToGame: {
      redirectingToGame: 'Redirigir al juego...',
      continueAs: 'Continuar como:',
      yes: 'Si',
      noLogOut: 'No, Deslogar'
    },
    character: {
      contextButton: {
        playNumetry: 'Jugar Numetry'
      },
      title: 'Selecciona un personaje.',
      audio: Character,
      input: {
        placeholder: 'Nombra a tu personaje...'
      },
      buttons: {
        save: {
          value: 'Guardar'
        },
        clearName: {
          value: '¡Borrar nombre!'
        },
        change: {
          value: 'Cambiar'
        }
      }
    },
    signUp: {
      usernameInUse: 'Este usuario ya existe, por favor selecciona uno nuevo.',
      usernameCannotBeUsed: 'Este nombre de usuario no puede ser utilizado.',
      usernameStep: {
        audio: SignUpUsername,
        beforeYouCanStart:
          '¡Hola! Antes de jugar debes crear una nueva cuenta de usuario.',
        usernameAlreadyTaken: 'Este usuario ya existe. Sugerencias:',
        username: 'Nombre de usuario'
      },
      passwordStep: {
        audio: SignUpPassword,
        alsoCreatePassword: 'También debes crear una contraseña.',
        password: 'Contraseña',
        passwordRepeat: 'Repetir contraseña:',
        inputPlaceholder:
          'Contraseña con al menos {{ minPasswordLength }} caracteres',
        minimumPasswordCharacters:
          'Tu contraseña debe contener al menos {{ minPasswordLength }} caracteres.',
        passwordNotEqual: 'Las contraseñas no coinciden.',
        togglePasswordVisibility: 'Revelar contraseña'
      },
      reviewStep: {
        audio: SignUpReview,
        greatRememberThis:
          '¡Excelente! Es importante que recuerdes esto. ¡Toma nota para que no lo olvides!',
        username: 'Nombre de usuario:',
        password: 'Contraseña',
        iveWrittenItDown: 'Ya lo tengo anotado'
      }
    },
    signIn: {
      usernameStep: {
        audio: SignInUsername,
        enterYourUsername: 'Escribe tu nombre de usuario para ingresar.',
        enterYourUsernameInput: 'Escribe tu nombre de usuario',
        notPlayedBefore: '¿No has jugado antes?',
        createNewUser: 'Crea una cuenta de usuario',
        usernameError: '¡Este nombre de usuario no existe!'
      },
      passwordStep: {
        audio: SignInPassword,
        youMustEnterYourPassword: 'Ahora ingresa tu contraseña.',
        passwordWithMinCharacter:
          'Contraseña con al menos {{ minPasswordLength }} caracteres',
        passwordError: 'Nombre de usuario o contraseña no válidos.'
      }
    }
  },
  municipality: {
    getStartedWithFeide: 'Empieza con Feide gratis'
  }
}
export default pageLocaleEs
